﻿
/**
 *  @ngdoc Resource
 *  @name Package Resource
 *  @description Application Service for Package
 * <h2>Dependencies</h2>
 * "$resource", "appSettings"
 */
(function () {
    "use strict";
    angular.module("common.services")
        .factory("vvProductResource", ["$resource", "appSettings", vvProductResource]);
    function vvProductResource($resource, appSettings) {
        return $resource(appSettings.serverPath + "/api/VVproducts", { id: '@id' },
            {
                'save':
                {
                    method: 'POST',
                    isArray: false,
                    url: appSettings.serverPath + 'api/VVproducts'
                },
                'update':
                {
                    method: 'PUT',
                    isArray: false,
                    url: appSettings.serverPath + 'api/VVproducts/update'
                },                
                'get':
                {
                    method: 'GET',
                    isArray: true,
                    url: appSettings.serverPath + 'api/VVproducts'
                }


            })
    }
}())